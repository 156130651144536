import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ShowMore from '@tedconf/react-show-more';

import ListingDetailFeed from './ListingDetailFeed';

const key = 'FGdHrOEzme6yufWFz7ap7hUhuAaRsl';

const StyledListings = styled.div`
	[class*='ListingDetail'] {
		margin-bottom: 12rem;

		@media (max-width: 768px) {
			margin-bottom: 6rem;
		}
	}

	> .column {
		&:nth-child(odd) [class*='ListingDetail'] {
			@media (min-width: 769px) {
				margin-right: 5%;
			}
		}

		&:nth-child(even) [class*='ListingDetail'] {
			@media (min-width: 769px) {
				margin-left: 5%;
			}
		}
	}

	.more-button {
		display: block;
		margin: 0 auto;
		background: none;
		border: none;
		text-transform: uppercase;
		position: relative;
		cursor: pointer;

		&[disabled] {
			display: none;
		}

		&::before {
			content: '';
			width: 1px;
			height: 1rem;
			background-color: ${(props) => props.theme.colors.primary};
			position: absolute;
			left: 50%;
			bottom: -1.5rem;
			transform: translateX(-50%);
		}

		&::after {
			content: '';
			width: 0.75rem;
			height: 0.75rem;
			position: absolute;
			left: 50%;
			bottom: -1.5rem;
			transform: translateX(-50%) rotate(45deg);
			border-right: 1px solid ${(props) => props.theme.colors.primary};
			border-bottom: 1px solid ${(props) => props.theme.colors.primary};
		}
	}
	form {
		.fieldwrap {
			height: 64px;
			border: 2px solid #888;
			margin-bottom: 0;
			padding: 0 48px;
			border-radius: 40px;
			@media (min-width: 769px) {
				height: 80px;
				margin-bottom: 24px;
			}
			label {
				margin-top: 8px;
				font-size: 14px;
				padding-left: 4px;
				color: #a9a9a9;
				display: block;
				&.hidden {
					display: none;
				}
			}
			input,
			select {
				font-size: 16px;
				border: none;
				outline: none;
				width: 100%;
				color: #222222 !important;
				height: 30px;
				@media (min-width: 769px) {
					height: 44px;
				}
				background-color: white !important;
				appearance: none;
				-webkit-appearance: none;
			}
			select {
				background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23333333%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  				background-repeat: no-repeat;
				background-position: right 0.7rem top 50%;
  				background-size: 0.9rem auto;
			}
			&.split {
				display: flex;
				div {
					flex: 1;
					&:first-child {
						margin-right: 16px;
					}
				}
			}
			&.big {
				input {
					height: 60px;
					@media (min-width: 769px) {
						height: 76px;
					}
				}
			}
		}
		.button-tray {
			text-align: center;
			margin-bottom: 48px;
			input,
			button {
				margin: 12px;
			}
		}
	}
`;

const ListingsListFeed = () => {
	const url =
		'https://api.repliers.io/listings?pageNum=1&resultsPerPage=12&brokerage=Parker%20Coulter%20Realty%20Brokerage%20Inc&sortBy=createdOnDesc';
	const options = {
		method: 'POST',
		headers: {
			accept: 'application/json',
			'content-type': 'application/json',
			'REPLIERS-API-KEY': key,
		},
	};

	const [listings, setListings] = useState([]);
	const [page, setPage] = useState(1);
	const [searchParams, setSearchParams] = useState({
		search: '',
		class: '',
		minPrice: '',
		maxPrice: '',
		minBeds: '',
		minBaths: '',
	});

	const updateParams = (name, value) => {
		console.log('update');
		console.log(name);
		console.log(value);
		let tempParams = searchParams;
		tempParams[name] = value;
		console.log(tempParams);
		setSearchParams(tempParams);
	};

	const search = () => {
		const url = `https://api.repliers.io/listings?pageNum=1&resultsPerPage=12${searchParams.search ? `&search=${searchParams.search}&sortBy=createdOnDesc` : ''
			}${searchParams.class ? `&class=${searchParams.class}` : ''}${searchParams.minPrice > 0 && searchParams.minPrice != ''
				? `&minPrice=${searchParams.minPrice}`
				: ''
			}${searchParams.maxPrice < 100000000 && searchParams.maxPrice != ''
				? `&maxPrice=${searchParams.maxPrice}`
				: ''
			}${searchParams.minBeds > 0 ? `&minBeds=${searchParams.minBeds}` : ''}${searchParams.minBaths > 0 ? `&minBaths=${searchParams.minBaths}` : ''
			}`;
		const options = {
			method: 'POST',
			headers: {
				accept: 'application/json',
				'content-type': 'application/json',
				'REPLIERS-API-KEY': key,
			},
		};
		fetch(url, options)
			.then((res) => res.json())
			.then((json) => {
				console.log(json);
				setListings(json.listings);
			})
			.catch((err) => console.error('error:' + err));
	};

	const nextPage = () => {
		const url =
			searchParams.search == '' &&
				searchParams.class == '' &&
				searchParams.minPrice == '' &&
				searchParams.maxPrice == '' &&
				searchParams.minBaths == '' &&
				searchParams.minBeds == ''
				? `https://api.repliers.io/listings?pageNum=${page +
				1}&resultsPerPage=12&brokerage=Parker%20Coulter%20Realty%20Brokerage%20Inc`
				: `https://api.repliers.io/listings?pageNum=${page +
				1}&resultsPerPage=12${searchParams.search ? `&search=${searchParams.search}` : ''
				}${searchParams.class ? `&class=${searchParams.class}` : ''}${searchParams.minPrice > 0 && searchParams.minPrice != ''
					? `&minPrice=${searchParams.minPrice}`
					: ''
				}${searchParams.maxPrice < 100000000 && searchParams.maxPrice != ''
					? `&maxPrice=${searchParams.maxPrice}`
					: ''
				}${searchParams.minBeds > 0 ? `&minBeds=${searchParams.minBeds}` : ''
				}${searchParams.minBaths > 0
					? `&minBaths=${searchParams.minBaths}`
					: ''
				}`;

		console.log(url);
		const options = {
			method: 'POST',
			headers: {
				accept: 'application/json',
				'content-type': 'application/json',
				'REPLIERS-API-KEY': key,
			},
		};
		fetch(url, options)
			.then((res) => res.json())
			.then((json) => {
				console.log(json);
				setListings(listings.concat(json.listings));
			})
			.catch((err) => console.error('error:' + err));
		setPage(page + 1);
	};

	useEffect(() => {
		fetch(url, options)
			.then((res) => res.json())
			.then((json) => {
				console.log(json);
				setListings(json.listings);
			})
			.catch((err) => console.error('error:' + err));
	}, []);

	const getSlug = (listing) => {
		return `${listing?.address?.streetNumber
			? `${listing.address.streetNumber.toLowerCase()}-`
			: ''
			}${listing?.address?.streetName
				? `${listing.address.streetName.toLowerCase()}-`
				: ''
			}${listing?.address?.streetSuffix
				? `${listing.address.streetSuffix.toLowerCase()}-`
				: ''
			}${listing?.address?.city ? `${listing.address.city.toLowerCase()}-` : ''}${listing.mlsNumber
			}`.replace(' ', '-');
	};

	return (
		<StyledListings>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					search();
				}}
			>
				<div className="columns">
					<div className="column is-12">
						<div className="fieldwrap big">
							<label htmlFor="search" className="hidden">
								Search
							</label>
							<input
								type="text"
								id="search"
								name="search"
								onBlur={(e) => {
									updateParams('search', e.target.value);
								}}
								placeholder="City, Neighbourhood, Address, MLS® Number"
							/>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-3">
						<div className="fieldwrap">
							<label htmlFor="type">Property Type</label>
							<select
								name="type"
								id="type"
								onBlur={(e) => {
									updateParams('class', e.target.value);
								}}
							>
								<option value="">Any</option>
								<option value="residential">Residential</option>
								<option value="condo">Condo</option>
								<option value="commercial">Commercial</option>
							</select>
						</div>
					</div>
					<div className="column is-5">
						<div className="fieldwrap split">
							<div>
								<label htmlFor="minPrice">Min. Price</label>
								<select
									name="minPrice"
									id="minPrice"
									onBlur={(e) => {
										updateParams('minPrice', e.target.value);
									}}
								>
									<option value="">-</option>
									<option value="100000">$100,000</option>
									<option value="200000">$200,000</option>
									<option value="300000">$300,000</option>
									<option value="400000">$400,000</option>
									<option value="500000">$500,000</option>
								</select>
							</div>
							<div>
								<label htmlFor="maxPrice">Max. Price</label>
								<select
									name="maxPrice"
									id="maxPrice"
									onBlur={(e) => {
										updateParams('maxPrice', e.target.value);
									}}
								>
									<option value="">-</option>
									<option value="300000">$300,000</option>
									<option value="400000">$400,000</option>
									<option value="500000">$500,000</option>
									<option value="600000">$600,000</option>
									<option value="700000">$700,000</option>
									<option value="800000">$800,000</option>
									<option value="900000">$900,000</option>
									<option value="100000000">$1M +</option>
								</select>
							</div>
						</div>
					</div>
					<div className="column is-4">
						<div className="fieldwrap split">
							<div>
								<label htmlFor="bedrooms">Bedrooms</label>
								<select
									name="bedrooms"
									id="bedrooms"
									onBlur={(e) => {
										updateParams('minBeds', e.target.value);
									}}
								>
									<option value="">-</option>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6+</option>
								</select>
							</div>
							<div>
								<label htmlFor="bathrooms">Bathrooms</label>
								<select
									name="bathrooms"
									id="bathrooms"
									onBlur={(e) => {
										updateParams('minBaths', e.target.value);
									}}
								>
									<option value="">-</option>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6+</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="button-tray">
					<button
						className="button button-2"
						onClick={(e) => {
							e.preventDefault();
						}}
					>
						CLEAR
					</button>
					<input type="submit" value="SEARCH" className="button button-3" />
				</div>
			</form>
			<div className="columns is-multiline">
				{listings &&
					listings.map((listing, i) => (
						<div className="column is-half" key={getSlug(listing)}>
							<ListingDetailFeed listing={listing} />
						</div>
					))}
			</div>
			<button
				className="more-button"
				onClick={() => {
					nextPage();
				}}
			>
				Load More
			</button>
		</StyledListings>
	);
};

export default ListingsListFeed;
